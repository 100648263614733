import * as React from "react"
// import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import {graphql} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";

const IndexPage = ({data}) => {
    const image = data.contentfulPerson.image.gatsbyImageData
    const bio = data.contentfulPerson.shortBio.shortBio
    console.log(data)

    return (
        <Layout>
            <Seo title="About Artifonica"/>
            <h1>About Artifonica</h1>
            <p>{bio}</p>
            <GatsbyImage image={image} alt="Bio pic"/>
        </Layout>
    )
}

export default IndexPage

export const aboutMe = graphql`
    query AboutMe {
        contentfulPerson(name: {eq: "Artifonica"}) {
            name
            id
            shortBio {
                shortBio
            }
            image {
                gatsbyImageData(cornerRadius: 25, quality: 5, width: 300)
            }
        }
    }
`
